<template>
  <div>
    <h2>
      {{ $t("select_actual_text") }} {{ currentlySelectedAsset }}
      <v-btn
        v-if="showDetails && currentlySelectedAsset !== '-'"
        @click="showAssetDetails"
        x-small
        class="elevation-0 ml-2"
        >{{ $t("details") }}</v-btn
      >
    </h2>
    <AssetDescription :asset="selectedAssetDescription" />
  </div>
</template>

<script>
import AssetDescription from "@/components/AssetDescription";

export default {
  name: "CurrentlySelectedAsset",
  props: {
    showDetails: {
      type: Boolean,
      default: false
    }
  },
  components: {
    AssetDescription
  },
  computed: {
    currentlySelectedAsset() {
      return this.$store.state.selectedAsset.objectId || "-";
    },
    selectedAssetDescription() {
      return this.$store.state.selectedAsset.assetDetails;
    }
  },
  methods: {
    showAssetDetails() {
      this.$router.push({ name: "Asset Details" });
    }
  }
};
</script>
