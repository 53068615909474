<template>
  <div>
    <h2 class="mb-4">{{ $t("select_asset_first") }}</h2>
    <v-btn @click="showAssetSearch">{{ $t("search_device") }}</v-btn>
  </div>
</template>

<script>
export default {
  name: "SelectAssetFirst",
  methods: {
    showAssetSearch() {
      this.$router.push({ name: "Home" });
    }
  }
};
</script>
