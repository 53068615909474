<template>
  <v-list-item v-if="Object.keys(asset).length">
    <v-list-item-content>
      <v-list-item-title
        >{{ asset.objectid }}: {{ asset.objectname }}
        {{ asset.objecttypename }} {{ asset.brand }} {{ asset.model }}
        {{ asset.sernum }}</v-list-item-title
      >
      <v-list-item-title
        >customername: {{ asset.customername }}, vehicletypeid:
        {{ asset.vehicletypeid }}, vehicletype: {{ asset.vehicletype }} ({{
          asset.comment
        }})</v-list-item-title
      >
      <v-list-item-subtitle
        ><b>objectcode:</b> {{ asset.objectcode }}, <b>serialnumber:</b>
        {{ asset.serialnumber }}, <b>imei:</b> {{ asset.imei }},
        <b>gsmnumber:</b> {{ asset.gsmnumber }}, <b>simcardsn:</b>
        {{ asset.simcardsn }}</v-list-item-subtitle
      >
      <v-list-item-subtitle
        ><b>mappername:</b> {{ asset.mappername }}, <b>typename:</b>
        {{ asset.typename }}, <b>modulehwversion:</b>
        {{ asset.modulehwversion }}</v-list-item-subtitle
      >
      <v-list-item-subtitle
        ><b>enabled:</b> {{ asset.enabled }}, <b>deleted:</b>
        {{ asset.deleted }}, <b>collectorname:</b> {{ asset.collectorname }}
      </v-list-item-subtitle>
      <v-list-item-subtitle>
        <b>streamurl:</b> {{ asset.streamurl }}
      </v-list-item-subtitle>
      <v-list-item-subtitle>
        <b>OBU:</b> {{ $t("obu.status") }}:
        {{ $t(`obu.${asset.obustatus}`) || "-" }}, ID: {{ asset.obuid || "-" }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action v-if="showButtons">
      <v-btn
        small
        color="success"
        @click="clickAction({ assetDetails: asset, route: 'Asset Details' })"
        >{{ $t("details") }}</v-btn
      >
      <v-btn
        small
        color="error"
        v-if="asset.mappername && asset.imei && asset.streamurl"
        @click="
          clickAction({ assetDetails: asset, route: 'Asset Sensor Stream' })
        "
        >{{ $t("messages") }}</v-btn
      >
    </v-list-item-action>
  </v-list-item>
</template>

<script>
export default {
  name: "AssetDescription",
  props: {
    asset: {
      type: Object,
      default() {
        return {};
      }
    },
    showButtons: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    clickAction(settings) {
      this.$emit("setAsset", settings);
    }
  }
};
</script>
