<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="5">
        <v-menu
          v-model="dateDropdown"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="selectedDate.date"
              label="Select date"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              dense
              :disabled="disabled"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="selectedDate.date"
            @input="dateDropdown = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="3">
        <GsNumber
          dense
          v-model="selectedDate.hour"
          :disabled="disabled"
          :maxlength="2"
          :maxValue="23"
        />
      </v-col>
      <v-col cols="1">
        <span class="d-block text-center">:</span>
      </v-col>
      <v-col cols="3">
        <GsNumber
          dense
          v-model="selectedDate.minute"
          :disabled="disabled"
          :maxlength="2"
          :maxValue="59"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import dayjs from "dayjs";
import GsNumber from "@/components/GsNumber";

export default {
  name: "DateTimePicker",
  components: {
    GsNumber
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      dateDropdown: false,
      selectedDate: {
        date: "",
        hour: 0,
        minute: 0
      }
    };
  },
  watch: {
    selectedDate: {
      deep: true,
      handler(val) {
        if (val.date) {
          this.$emit(
            "input",
            dayjs(`${val.date} ${val.hour}:${val.minute}`).toISOString()
          );
        }
      }
    },
    value: {
      immediate: true,
      handler(val) {
        if (val) {
          this.selectedDate.date = dayjs(val).format("YYYY-MM-DD");
          this.selectedDate.hour = parseInt(dayjs(val).format("HH"), 10);
          this.selectedDate.minute = parseInt(dayjs(val).format("mm"), 10);
        } else {
          this.selectedDate.date = "";
          this.selectedDate.hour = 0;
          this.selectedDate.minute = 0;
        }
      }
    }
  }
};
</script>
