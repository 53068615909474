<template>
  <v-card flat outlined class="pa-0">
    <v-card-text>
      <v-container>
        <v-row dense>
          <v-col
            ><span class="overline"
              ><b>{{ $t("stream_date_title") }}</b></span
            ></v-col
          >
        </v-row>
        <v-row dense>
          <v-col
            ><span class="overline">{{ $t("from") }}:</span></v-col
          >
          <v-col v-if="streamDate.from">{{
            formatTheDate(streamDate.from)
          }}</v-col>
          <v-col v-else>{{ $t("now") }}</v-col>
        </v-row>
        <v-row dense v-if="streamDate.to">
          <v-col
            ><span class="overline">{{ $t("to") }}:</span></v-col
          >
          <v-col>{{ formatTheDate(streamDate.to) }}</v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-btn
      color="primary"
      elevation="2"
      small
      right
      bottom
      absolute
      fab
      @click="showDateSetter"
    >
      <v-icon>mdi mdi-calendar</v-icon>
    </v-btn>
    <!-- date setters -->
    <v-dialog
      width="60%"
      v-model="showDateSettings"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t("date_settings_title") }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="1">
                <span class="overline">{{ $t("from") }}:</span>
              </v-col>
              <v-col cols="4" class="ml-4">
                <v-checkbox
                  v-model="fromNow"
                  :label="$t('from_now')"
                ></v-checkbox>
              </v-col>
              <v-col>
                <DateTimePicker :disabled="fromNow" v-model="fromDate" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="1">
                <span class="overline">{{ $t("to") }}:</span>
              </v-col>
              <v-col>
                <DateTimePicker :disabled="endless" v-model="toDate" />
              </v-col>
              <v-col cols="4" class="mr-4">
                <v-checkbox
                  v-model="endless"
                  :label="$t('endless')"
                  class="d-table ml-auto"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="saveDateSettings"
            class="mr-4"
          >
            {{ $t("save") }}
          </v-btn>
          <v-btn color="blue darken-1" text @click="showDateSettings = false">
            {{ $t("cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import DateTimePicker from "@/components/DateTimePicker";
import formatDate from "@/formatters/formatDate";
import dayjs from "dayjs";

export default {
  name: "StreamDateSelector",
  components: {
    DateTimePicker
  },
  data() {
    return {
      showDateSettings: false,
      fromNow: false,
      endless: false,
      fromDate: null,
      toDate: null
    };
  },
  computed: {
    streamDate() {
      return this.$store.state.streamDate;
    }
  },
  methods: {
    formatTheDate(date) {
      return formatDate(date);
    },
    showDateSetter() {
      if (!this.streamDate.from) {
        this.fromNow = true;
        this.fromDate = null;
      } else {
        this.fromNow = false;
        this.fromDate = this.streamDate.from;
      }
      if (!this.streamDate.to) {
        this.endless = true;
        this.toDate = null;
      } else {
        this.endless = false;
        this.toDate = this.streamDate.to;
      }
      this.showDateSettings = true;
    },
    saveDateSettings() {
      // nullables?
      if (this.fromDate && this.fromNow) {
        this.fromDate = null;
      }
      if (this.toDate && this.endless) {
        this.toDate = null;
      }
      if (dayjs(this.toDate).isBefore(dayjs(this.fromDate))) {
        this.toDate = null;
        this.endless = true;
      }
      // set store date
      this.$store.commit("setStreamDate", {
        from: this.fromDate,
        to: this.toDate
      });
      this.showDateSettings = false;
      this.$store.dispatch("resetMessageStream");
    }
  }
};
</script>
